/* @import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,500i,600,600i,700,700i,800|Open+Sans:400,400i,600,600i,700,700i,800&display=swap); */

#progressBar {
  background-color: #247ba0;
  width: 150px;
  padding: 10px;
  border-radius: 5px;
  animation: progressBar 3s ease;
  animation-fill-mode: both;
  text-align: center;
  box-sizing: content-box;
  animation-iteration-count: 2;
}

.card__profileimg {
  height: 285px;
  object-fit: cover;
}

.skill__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.skill {
  height: 160px;
  width: 160px;
  position: relative;
}

.outer {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  padding: 20px;
  /* border: 1px solid rebeccapurple; */
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.inner {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid rebeccapurple; */
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

.overlay {
  z-index: 100;
}

/* cards */
#user--image {
  height: 230px;
  width: 200px;
  border-radius: 50%;
}

/* section */

/* admin dashboard*/
.user__progress {
  display: grid;
  grid-template-columns: 15% 80%;
}

.list-item {
  border-bottom: 1px solid rgba(77, 75, 75, 0.178);
}

.list-item:last-child {
  border-bottom: none;
}

.profile-card {
  width: 200px;
  height: 200px;
  object-fit: 'cover';
}

i {
  text-decoration: none;
  font-style: normal;
}

.icons {
  width: 20px;
  padding: 5px;
}
