.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.item-animated {
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #0f4042;
  margin: 60px 20px;
}

.box-title {
  -webkit-animation: Animate2 3s infinite alternate ease-in-out;
  animation: Animate2 3s infinite alternate ease-in-out;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  text-shadow: 0 0 80px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
}
.box-title .sub-title {
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
}
.box-title .title {
  font-weight: bold;
  font-size: 60px;
  letter-spacing: 10px;
}

.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
