.admin-right-side-content {
  /* padding-left: 15.625rem; */
  /* padding-left: 15.625rem; */
}

.CourseListItem {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: inherit;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.selectTypeClass:disabled {
  background-color: #f5eeee;
}

.miniTitle {
  font-family: "Muli-SemiBold";
  color: #0873bbd5;
}

.CourseListItem:disabled {
  background-color: #e5e9edc4;
  /* color:#66ccff8c */
  border-radius: 0.8rem 0.8rem 0 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 285px !important;
}

.swiper-slide .testCards {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adminSideBarTogglerCont {
  /* opacity: 0.7; */
  position: fixed;
  top: 6rem;
  z-index: 1000;
  width: max-content;
  position: fixed;
  left: 250px;
}

/* //////////////////////////////////////////////////////////// */
/* From oauth console */
.outletWithSideBar {
  /* background-color: #d8f2ff; */
  /* width: 83.36%; */
  width: 83.36%;
  position: relative;
  left: 16.6%;
  transition: all 0.6s ease;
}

.togglerWithSideBar {
  top: 6rem;
  z-index: 1000;
  width: max-content;
  position: fixed;
  left: 250px;
}

.outletWithoutSideBar {
  /* background-color: #d8f2ff; */
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
  position: absolute;
  left: 0;
  transition: all 0.6s ease;
}

.togglerWithoutSideBar {
  left: 2rem;
  top: 6rem;
  z-index: 1000;
  width: max-content;
  position: fixed;
}

/* //////////////////////////////////////////////////////////// */

/* //////////////////////  Sidebar navigation animation //////////////////// */

/* ///////////////////// slideInLeft /////////////////////////////// */
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* ///////////////////////// slideInRight /////////////////////// */
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}

/* ///////////////////// slideInRight for button toggler /////////////////////////////// */
.slideInRightToggler {
  -webkit-animation-name: slideInRightToggler;
  animation-name: slideInRightToggler;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInRightToggler {
  0% {
    -webkit-transform: translateX(-450%);
    transform: translateX(-450%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideInRightToggler {
  0% {
    -webkit-transform: translateX(-450%);
    transform: translateX(-450%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/* ///////////////////// slideInLeft for button toggler /////////////////////////////// */
.slideInLeftToggler {
  -webkit-animation-name: slideInLeftToggler;
  animation-name: slideInLeftToggler;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInLeftToggler {
  0% {
    -webkit-transform: translateX(460%);
    transform: translateX(460%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@keyframes slideInLeftToggler {
  0% {
    -webkit-transform: translateX(460%);
    transform: translateX(460%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

/* admin-right-side-content */
@media (max-width: 767px) {
  .tableCard {
    margin-bottom: 2rem !important;
  }
  .admin-right-side-content {
    /* padding-left: 7rem;  */
  }

  .sideBarUl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .custom-ui {
    width: 100% !important;
  }

  .outletWithoutSideBar {
    padding-left: 0;
    padding-right: 0;
  }

  .togglerWithoutSideBar {
    left: 2rem !important;
  }

  .adminSideBarTogglerCont {
    justify-content: center;
    left: 110px;
  }

  .slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInRight {
    0% {
      -webkit-transform: translateX(-80%);
      transform: translateX(-80%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(15%);
      transform: translateX(15%);
    }
  }
  @keyframes slideInRight {
    0% {
      -webkit-transform: translateX(-80%);
      transform: translateX(-80%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(15%);
      transform: translateX(15%);
    }
  }

  .slideInRightToggler {
    -webkit-animation-name: slideInRightToggler;
    animation-name: slideInRightToggler;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInRightToggler {
    0% {
      -webkit-transform: translateX(-200%);
      transform: translateX(-200%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
  }
  @keyframes slideInRightToggler {
    0% {
      -webkit-transform: translateX(-200%);
      transform: translateX(-200%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
  }

  /* ///////////////////////// */
  .slideInLeftToggler {
    -webkit-animation-name: slideInLeftToggler;
    animation-name: slideInLeftToggler;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInLeftToggler {
    0% {
      -webkit-transform: translateX(270%);
      transform: translateX(270%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
  @keyframes slideInLeftToggler {
    0% {
      -webkit-transform: translateX(270%);
      transform: translateX(270%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
}

@media (max-width: 480px) {
  .activeMembersCard {
    margin-bottom: 1rem !important;
  }

  .outletWithoutSideBar {
    padding-left: 0;
    padding-right: 0;
  }

  .rmXPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .custom-ui-messg {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .permissionFormContainer,
  .permissionModalHeader {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .togglerWithoutSideBar {
    left: 0.5rem !important;
  }

  .adminSideBarTogglerCont {
    justify-content: center;
    left: 110px;
  }

  .slideInRightToggler {
    -webkit-animation-name: slideInRightToggler;
    animation-name: slideInRightToggler;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInRightToggler {
    0% {
      -webkit-transform: translateX(-200%);
      transform: translateX(-200%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
  }
  @keyframes slideInRightToggler {
    0% {
      -webkit-transform: translateX(-200%);
      transform: translateX(-200%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }
  }

  /* ///////////////////////// */
  .slideInLeftToggler {
    -webkit-animation-name: slideInLeftToggler;
    animation-name: slideInLeftToggler;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  @-webkit-keyframes slideInLeftToggler {
    0% {
      -webkit-transform: translateX(270%);
      transform: translateX(270%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
  @keyframes slideInLeftToggler {
    0% {
      -webkit-transform: translateX(270%);
      transform: translateX(270%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }
}
.custom-height {
  height: 35rem;
}

@media (min-width: 768px) {
  .custom-height {
    height: 30rem;
  }
}
