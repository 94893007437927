:root {
  --color-primary: white;
  --color-secondary: #000000;
  --color-tertiary: #2d6502;
  --color-alternate: #808080;
  --color-sec: #7cfc00;
  --color-secondary-2: #377409;
  --gradient-primary: linear-gradient(147deg, #000000 0%, #434343 74%);
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700");

body::before {
  display: block;
  content: "";
  height: 60px;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}
.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
/*nav style*/
.navigation {
  background: rgba(0, 0, 0, 0.73) none repeat scroll 0 0;
  margin-bottom: 0;
  border-bottom: 0;
  padding: 20px 0;
  transition: 0.3s all;
}

.nav-item a:hover,
.nav-item a:active,
.nav-item a.active,
.nav-item button:hover,
.nav-item button:active {
  color: #ffeb3b;
}

.sticky-header {
  background: rgba(0, 0, 0, 0.73);
}

.menu li a {
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  /* transition: 0.7s; */
  position: relative;
}
.menu li a:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  opacity: 0;
  width: 0;
  height: 1px;
  transition: 0.7s;
  background: #ffeb3b;
}
.menu li a:hover {
  text-decoration: none;
  color: #ffeb3b;
}
.menu li a:hover:before {
  opacity: 1;
  width: 100%;
  left: 0;
}
.menu li a:hover,
.menu li a:focus {
  background: transparent;
}

.hidden {
  opacity: 0;
}

.user-message {
  margin: auto;
  width: 90%;
  padding: 0.5rem;
  border-radius: 3px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;
}

.user-message--error {
  border-color: red;
  background: rgb(255, 176, 176);
  color: red;
}
.user-message--success {
  border-color: rgb(7, 245, 27);
  background: rgb(183, 255, 176);
  color: rgb(1, 15, 4);
}
.message {
  z-index: 20;
  position: absolute;
  margin-top: 15px;
  margin-bottom: 15px;
  left: 0;
  right: 0;
}

.testimonials {
  width: 128px;
  height: 128px;
  object-fit: cover;
}
.button-archive {
  margin-left: 350px;
  margin-top: -30px;
}
.error {
  height: 80vh;
}
.error-text {
  margin-top: 15%;
  text-align: center;
  font-weight: 700;
}

.accordion-text {
  padding: 10px 30px 0 30px;
}

.row-eq-height {
  display: flex;
  flex-wrap: wrap;
}

.testionial-cards {
  padding: 0.5rem;
}

#learnInfo {
  font-weight: 900;
}

#lead {
  font-weight: 500;
}

#utilSize {
  font-weight: 400;
}

.border-to-blue {
  border-color: #66ccff;
}

.phoneNoInput {
  width: 100% !important;
  padding: 0.5rem 0.875rem 0.5rem 3.75rem !important;
  border-top-left-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-radius: 4px !important;
  /* border-top-right-radius: 0.9375rem !important;
  border-bottom-right-radius: 0.9375rem !important; */
}

.phoneNoInputProf {
  width: 100% !important;
  padding: 1rem 0.875rem 1rem 3.75rem !important;
  border-top-left-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-radius: 4px !important;
  /* border-top-right-radius: 0.9375rem !important;
  border-bottom-right-radius: 0.9375rem !important; */
}

.sideBarUl {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

fieldset legend span {
  font-size: 1.05rem !important;
  text-transform: uppercase !important ;
  font-weight: 400 !important;
}

.MuiTypography-root {
  font-family: inherit !important;
}

@media (min-width: 768px) {
  .news-input {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .tableCard {
    margin-bottom: 2rem !important;
  }

  .main-header__nav {
    display: flex;
  }

  #side-menu-toggle {
    display: none;
  }
  .user-message {
    width: 30rem;
  }

  .sideBarUl {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (max-width: 600px) {
  img#hero-image {
    width: 351px;
    height: 323px;
  }

  section#showcase-section {
    padding: 2rem 1rem 4rem 1rem !important;
  }

  .sideBarUl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  a#berenText {
    font-size: 1.2rem;
  }

  .hero-img {
    padding-top: 2rem;
  }

  section#info-tiles-section {
    padding: 0.5rem !important;
  }

  section#learn {
    padding: 2rem 0.5rem !important;
  }

  div#learnInfo {
    padding: 2.5rem 2rem !important;
  }

  section#instructors {
    padding: 2rem 0.5rem !important;
  }
}

@media (max-width: 480px) {
  .remTablePaddingX {
    padding-right: 12.5px !important;
    padding-left: 12.5px !important;
  }

  p#utilSize {
    font-size: 1.1rem !important;
  }

  .sideBarUl {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .CourseProgressHeader {
    order: 1 !important;
  }

  .testimonialBtn {
    order: 5 !important;
  }

  .courseOptions {
    order: 1 !important;
    margin-top: 1rem !important;
  }

  .viewCourseUpdateHistoryBtn {
    height: 56px !important;
  }

  .courseAccordion {
    order: 4 !important;
  }

  .progressChart {
    order: 3 !important;
  }
}

@media (max-width: 415px) {
  a#berenText {
    font-size: 1rem;
  }
}

@media (min-width: 575px) {
  p#faqParagraph {
    font-size: 1.1rem;
  }
  a#accordionLinks {
    font-size: 1.05rem;
  }

  p#accordionContent {
    font-size: 0.95rem;
  }

  p#utilSize {
    font-size: 1.06rem;
  }

  #testimonialContent {
    font-size: 0.95rem;
  }

  #testimonialModalContent {
    font-size: 1rem;
  }
}
