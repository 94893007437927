body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.2rem;
  place-items: center;
  grid-auto-rows: minmax(min-content, max-content);
  color: #2277cb;
}

.fa-home,
.fa-phone,
.fa-envelope {
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
  .contact--image {
    grid-row: 1;
    grid-column: 1 / span end;
  }
  .col-small-1 {
    grid-column: 1 / span end;
    grid-row: 2;
    border: none;
    border-bottom: 2px solid #ba54f5;
    text-align: center;
  }
  .col-small-2 {
    grid-column: 1 / span end;
    grid-row: 3;
    border: none;
    border-bottom: 2px solid #ba54f5;
    text-align: center;
  }
  .col-small-3 {
    grid-column: 1 / span end;
    grid-row: 4;
    border: none;
    text-align: center;
  }
}

.bi {
  /* color: #198754; */
  padding-right: 10px;
}
.card-icons__gray {
  background-color: #181818;
}
.hidden {
  opacity: 0;
  transform: translateY(20rem);
}

.border {
  border-top: 2px solid rgba(236, 239, 241, 0.07);
  height: 1px;
  margin: 15px auto 0;
  position: relative;
  width: 30%;
}
.border:before {
  background-color: #fff;
  content: "";
  height: 6px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  top: -4px;
  width: 50px;
  background-color: #57cbcc;
}
.title {
  padding-bottom: 60px;
}
.title h2 {
  text-transform: capitalize;
  font-weight: 700;
  font-size: 38px;
}

.invalid {
  border-color: red;
}

.adsadfae {
  color: #0289d149;
}
